class MakerStarter {
  constructor() {
    this.init();
  }

  init() {
    console.log("Maker Starter Main Script initialized");
  }
}

export default MakerStarter;
